<template>
  <div class="convenience">
    <div class="search-container">
      <el-row style="line-height:48px">
        <el-col :span="8">
          <el-form ref="form" :model="form" label-width="0px">
            <el-col class="head-left-wrapper" v-if="false">
              <div class="input-wrapper">
                <el-input @keyup.enter.native="searchData" placeholder="请输入关键字" v-model="form.searchValue"></el-input>
                <img @click="searchData" class="input-image" src="../../../../../assets/images/input_search.png" alt="" srcset="">
              </div>
            </el-col>
          </el-form>
        </el-col>
        <el-col :span="16">
        <!-- <el-col :span="16" style="text-align:right"> -->
          <div class="btn-wrapper">
            <el-button v-if="false" @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
            <el-button  v-if="buttonAuth.includes('venueMana:venue:add')" @click="changeAddDialog(true)">{{$t('add')}}</el-button>
          </div>
          <el-button @click="goToSwitchRecord" class="search-button">开关记录</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
        :data="tableData"
        :height="tableHeight"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        border
      >
        <el-table-column
         	prop="deviceCode"
          min-width="115"
          align="center"
					label="设备ID">
        </el-table-column>
        <el-table-column
          prop="deviceName"
          align="center"
          min-width="168"
					label="设备名称">
        </el-table-column>
        <el-table-column
          prop="deviceUnit"
          align="center"
          min-width="115"
          label="设备型号">
        </el-table-column>
         <el-table-column
          prop="venueName"
          min-width="168"
          align="center"
          label="场馆"
        ></el-table-column>
         <el-table-column
          prop="sportsProjectName"
          align="center"
          min-width="120"
          label="运动项目"
        >
        </el-table-column>
         <el-table-column
          prop="spaceName"
          min-width="168"
          align="center"
          label="场地"
        ></el-table-column>

        <el-table-column
          prop="location"
          min-width="120"
          align="center"
          label="安装地点"
        ></el-table-column>
        <el-table-column
          prop="gatewayName"
          min-width="120"
          align="center"
          label="配套网关"
        ></el-table-column>
        <el-table-column
          prop="online"
          align="center"
          min-width="115"
          label="在线状态"
        >
          <template slot-scope="scope">
            <div :class="scope.row.online === '0' ? 'onLine': 'offLine'">{{ scope.row.online === '0' ? '在线' : '离线' }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createTime"
          align="center"
          min-width="168"
          label="添加时间"
        ></el-table-column>
        <el-table-column label="操作" min-width="220" align="center">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.checked"
              @change='changeStatus(scope.row)'
              active-text="关闭"
              inactive-text="开启">
            </el-switch>
            <!-- <span class="oper-text"  @click="changeOpenDialog(scope.row)" v-if="switchControlArg.status == 1">{{ $t('openLight') }}</span>
            <span class="oper-text"  @click="changeOpenDialog(scope.row)" v-else>{{ $t('closeLight') }}</span> -->
            <span  class="line"></span>
            <!-- <span class="oper-text"  @click="changeOpenDialog(scope.row)">{{ $t('closeLight') }}</span>
            <span  class="line"></span> -->
            <span class="oper-text" v-auth="'fitnessFacilities:facilityManagement:edit'" @click="changeAddDialog(true, true, scope.row)">{{$t('edit')}}</span>
            <span class="line"></span>
            <span class="oper-text" v-if="buttonAuth.includes('system:user:remove')" @click="openDeleteDialog(scope.row.id, 1)">{{$t('delete')}}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="page-content">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="form.total"
      ></el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog
      ref="addDialog"
      :Language="Language"
      :show="addDialog.visible"
      :isEdit="addDialog.isEdit"
      :sportsProjectList="sportsProjectList"
      :gatewayList="gatewayList"
      @closeDialog="changeAddDialog"
    ></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog
      ref="confirmDialog"
      :Language="Language"
      :show="confirmDialog.visible"
      :type="confirmDialog.type"
      @sureDialog="sureConfirmDialog"
      @closeDialog="changeConfirmDialog"
    ></confirm-dialog>
  </div>
</template>
<script>
import IndexClass from "./indexClass";
import mixin from "@/mixins/index";
import apis from "@/apis";
import { mapState } from "vuex";
import addDialog from "./dialog/addDialog.vue";
import confirmDialog from "@/components/confirmDialog.vue";
import createDeleteMixin from '@/mixins/createDeleteMixin.js'
// import axios from '@/plugins/axios';

export default {
  mixins: [mixin, createDeleteMixin(apis.fitnessDeviceManage)],
  components: { addDialog, confirmDialog },
  data() {
    return {
      statusSwitch: true, //一键全开/关键
      form: new IndexClass("form"), // 实例化一个表单的变量
      status: "",
      sportsProjectList: [],
      gatewayList: [],
      tableData: [],
      category: [], // tab栏
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1, //1:删除 9：发布
        data: {},
      },
      matchStatus: 0, // 0 发布 1 下架 2 禁用
      topStatus: 0, // 0 置顶 1 取消置顶 2 禁用
      switchControlArg: {
        controlType: 1, //  0网关群控 1设备单控
        deviceCode: "", //设备编号
        status: 1, //0开 1关
      },
      switchValue: "",
    };
  },
  computed: {
    ...mapState(["Language", "DROPDOWNBOX", "TOKEN", "venueId"]),
  },
  methods: {
    changeStatus(item) {
      console.log("changeStatus", item)
      this.switchControlArg.status = item.checked === false ? 0 : 1
      this.switchControlArg.deviceCode = item.deviceCode
      this.$http.post(apis.switchControl, {
        ...this.switchControlArg,
      }).then((res) => {
        console.log("res", res)
        if (res.data && res.data.code === 0) {
           this.$message.success("修改成功!")
           this.tableData.forEach((i) => {
             if (i.deviceCode === item.deviceCode) {
               this.switchControlArg.status = 0
             } else {
               this.switchControlArg.status = 1
             }
             console.log("this.switchControlArg.status", this.switchControlArg.status)
           })
        }
      })
      // this.switchControlArg
      // this.tableData.map((i) => {
        // if (i.id === e) {
        //   i.checked = true
        // }
        // return i
      // })
      // const index = this.tableData.findIndex((item) => item.checked);
    },
    // 开关灯
    changeOpenDialog(item) {
      console.log("tableData", this.tableData)
      // TODO: 需要真实设备一起联调
      // const data = {
      //   controlType: 1,
      //   deviceCode: item.deviceCode,
      //   status: 1,
      // }
      this.switchControlArg.deviceCode = item.deviceCode
      this.$http.post(apis.switchControl, {
        ...this.switchControlArg,
      }).then((res) => {
        console.log("res", res)
        if (res.data && res.data.code === 0) {
           this.$message.success("修改成功!")
           this.tableData.forEach((i) => {
             if (i.deviceCode === item.deviceCode) {
               this.switchControlArg.status = 0
             } else {
               this.switchControlArg.status = 1
             }
             console.log("this.switchControlArg.status", this.switchControlArg.status)
           })
        }
      })
      // const xhr = axios
      // xhr.post(apis.switchControl, data, {
      //    headers: {
      //      'Content-Type': 'application/json',
      //     },
      // }).then((res) => {
      //   console.log("res", res)
      // })
    },
    // 跳转进入开关记录页面
    goToSwitchRecord() {
      this.$router.push("./switchRecord")
      // this.$message.success('敬请期待')
    },
    // 清空按钮
    cleanForm() {
      this.form = new IndexClass('form')
      this.$nextTick(() => {
        this.searchData()
      })
    },
    // 查询
    searchData() {
      // const formData = { ...this.form };
       const formData = {
        venueId: this.venueId,
        deviceType: "7",
        isLightPage: '1',
        page: this.form.pageNum,
        pageSize: this.form.pageSize,
      }
      console.log("+++++++++++++formData:", formData)
      this.$http.get(apis.getIotDeviceListByType, {
        params: formData,
      }).then((res) => {
        if (res.data.code === 0) {
          // this.tableData = res.data.data.iotDeviceInfoList;
          if (res.data && res.data.data && res.data.data.iotDeviceInfoList) {
            const arr = res.data.data.iotDeviceInfoList;
            this.tableData = arr.map((item) => {
              return {
                ...item,
                checked: false,
              }
            })
          }
          console.log(" this.tableData ", this.tableData)
          this.sportsProjectList = res.data.data.sportsProjectList;
          this.gatewayList = res.data.data.gatewayList;
          this.form.total = res.data.data.totalCount
          this.venueName = res.data.data.venueName
        }
      });
    },
    // 新增修改
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit;
        if (isEdit) {
          this.$refs["addDialog"].getDataQuery(data);
        }
      } else {
        this.searchData();
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus;
      });
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.ids = "";
        this.searchEvent();
      }
    },
    /**
     * 删除和发布点击确认
     * id: 对应的id值
     * type 1：删除 9:发布
     */
    /* eslint-disable */
    openDeleteDialog(id, type) {
      let text = ""
      switch (type) {
        case 30:
          text = "您确认要打开该闸机吗?"
          break;
        case 31:
          text = "您确认要关闭该闸机吗?"
          break;
        default:
          text = "您确认要关闭该闸机吗?"
          break;
      }
      this.confirmDialog.type = type
      this.confirmDialog.data.ids = id
      this.changeConfirmDialog(true, type)
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type;
      this.confirmDialog.visible = dialogStatus;
      if (!dialogStatus) {
        this.confirmDialog.data.ids = "";
        this.searchEvent();
      }
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      this.$http.delete(`${apis.delIotDevice}?id=${this.confirmDialog.data.ids}`).then((res) => {
        if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
          this.$message.success("删除成功!")
          this.searchEvent()
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.scoped.less";
</style>
<style lang="less" scoped>
@import "~@/assets/styles/mixin.less";
.convenience {
  .search-container {
    margin: 0;
    height: 50px;
    overflow: hidden;
    .head-left-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: right;
      .input-wrapper {
        width: 360px;
        position:relative;
      }
      .input-image {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        cursor: pointer;
      }
      .el-button {
        height: 36px;
        margin-left: 15px;
      }
    }
  }
  .page-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
  }
  .el-tabs__active-bar {
    display: none;
  }
}
.allOff-wrapper{
  display: inline-block;
  margin-right: 38px;
  .allAndOff{
    margin-right: 10px;
  }
}
.btn-wrapper {
  display: inline-block;
  margin-right: 32px;
}
.oper-text{
  color: #439BFF;
  font-size: 12px;
  cursor: pointer;
}
.line{
  display: inline-block;
  margin:0 8px;
  width: 1px;
  height: 13px;
  background: #D8D8D8;
}
.onLine{
  color: #6DD400;
}
.offLine{
  color: #E02020;
}


</style>
