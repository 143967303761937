class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.deviceType = '7'
      this.sportsProjectCode = ''
      this.sportsProjectName = ''
      this.spaceId = ''
      this.deviceCode = ''
      this.deviceName = ''
      this.deviceUnit = ''
      this.location = ''
      this.parentId = ''
      this.spaceName = ''
    } else if (type === 'rule') {
      this.sportsProjectCode = [{ required: true, message: '请选择运动项目', trigger: ['change', 'blur'] }]
      this.deviceCode = [{ required: true, message: '请输入设备ID', trigger: 'blur' }]
      this.deviceName = [{ required: true, message: '请输入设备名称', trigger: 'blur' }]
      this.spaceId = [{ required: true, message: '请选择场地', trigger: 'blur' }]
      this.spaceName = [{ required: true, message: '请选择场地', trigger: 'blur' }]
      this.deviceUnit = [{ required: true, message: '请输入设备型号', trigger: 'blur' }]
      this.location = [{ required: true, message: '请输入安装地点', trigger: 'blur' }]
      this.parentId = [{ required: true, message: '请选择配套网关', trigger: ['change', 'blur'] }]
    }
  }
}
export default searchFrom
