<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? '修改设备' : '新增设备'}`"
      :visible.sync="show"
      @opened="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="50%"
    >
    <el-form ref="form" :model="form" label-width="120px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="场馆/中心" prop="seleVenueName">
              <el-select v-model="venueId" style="width:100%" disabled>
                <el-option v-for="item in venueList" :key="item.id" :label="item.venueName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="24">
            <el-form-item label="运动项目" prop="sportsProjectCode">
              <el-select v-model="form.sportsProjectCode" @change="getSportId($event)" style="width:100%" clearable >
                <el-option v-for="item in sportsProjectList" :key="item.sportsProjectCode" :label="item.sportsProjectName" :value="item.sportsProjectCode"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
           <el-col :span="24">
            <el-form-item label="运动项目" prop="sportsProjectCode">
              <el-select v-model="form.sportsProjectCode" @change="getSportId($event)" style="width:100%" clearable >
                <el-option v-for="item in sportsProjectList" :key="item.sportsProjectCode" :label="item.sportsProjectName" :value="item.sportsProjectCode"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
           <el-col :span="24">
            <el-form-item label="场地" prop="spaceName">
              <el-select v-model="form.spaceName" style="width:100%" @change="getSpace($event)">
                <el-option v-for="item in spaceList" :key="item.id" :label="item.spaceName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
         <!-- <el-col :span="24">
            <el-form-item label="场地" prop="spaceName">
              <el-select v-model="form.spaceName" style="width:100%" @change="getSpace($event)">
                <el-option v-for="item in spaceList" :key="item.id" :label="item.spaceName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->
          <el-col :span="24">
            <el-form-item label="设备ID" prop="deviceCode">
              <el-input clearable v-model="form.deviceCode" placeholder="请输入" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备名称" prop="deviceName">
              <el-input clearable v-model="form.deviceName" placeholder="请输入" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="设备型号" prop="deviceUnit">
              <el-input clearable v-model="form.deviceUnit" placeholder="请输入" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="安装地点" prop="location">
              <el-input clearable v-model="form.location" placeholder="请输入" maxlength="30"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="配套网关" prop="parentId">
              <el-select v-model="form.parentId" style="width:100%" clearable>
                <el-option v-for="item in gatewayList" :key="item.gatewayId" :label="item.gatewayName" :value="item.gatewayId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <div class="footer-confirm" @click="onSave">{{$t('sure')}}</div>
        <div class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import mixin from "@/mixins/dialogMixin";
import twoStepDialogMixin from "@/mixins/twoStepDialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";

export default {
  mixins: [mixin, twoStepDialogMixin],
  components: {
    // dialogMap,
  },
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    sportsProjectList: {
      required: true,
    },
    gatewayList: {
      required: true,
    },
  },
  data() {
    return {
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      rowData: {},
      apis,
      showMap: true,
      isRequired: true,
      showPic: true,
      spaceList: [], //场地
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN", "venueList", "venueId"]),
  },
  mounted() {
    console.log("venueList", this.venueList)
  },
  methods: {
    getSpace(e) {
      this.form.spaceId = e
      console.log("getSpace====e", e)
      this.spaceList.forEach((ele) => {
        if (ele.id === e) {
          this.form.spaceName = ele.spaceName
        }
      })
    },
    getSportsProjectCode(sportsProjectCode) {
      this.$http.get(`${apis.getSportsProjectCode}?sportsProjectCode=${sportsProjectCode}&venueId=${this.venueId}`).then((res) => {
        if (res.data.code === 0) {
          this.spaceList = []
          if (res.data.rows.length > 0) {
            this.spaceList = res.data.rows
            console.log("res.rows+++=spaceList++++++", res.data.rows)
            this.form.spaceId = ''
            this.form.spaceName = ''
          } else {
            this.form.spaceId = ''
          }
        }
      });
    },
    getSportId(e) {
      if (e) {
        this.getSportsProjectCode(e)
      }
    },
    /**
     * @function 关闭弹窗
     * @description 先初始化，然后传值到组件外部进行关闭
     **/
    async closeDialog() {
      await this.init()
      this.$emit('closeDialog', false)
    },
    ...mapMutations(['setVenueId', "setVenueList"]),
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
      console.log("我初始化了");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    onSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (!this.form.parentId) this.form.gatewayName = ""
          // 编辑
          if (this.isEdit) {
            const params = { ...this.form }
            console.log("this.form", this.form)
            params.id = this.rowData.id
            this.$http
              .post(apis.updateIotDevice, params)
              .then((res) => {
                if (res.data.code === 0) {
                  this.$message.success("修改成功！")
                  this.closeDialog();
                }
              });
          } else { // 新增
          console.log("++++++++++新增智能灯+++++++++++:", this.form)
            this.$http
              .post(apis.addIotDevice, this.form)
              .then((res) => {
                if (res.data.code === 0) {
                  this.$message.success("新增成功！")
                  this.closeDialog();
                }
              });
          }
        }
      })
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      console.log("获取回显的数据===>", data)
      this.rowData = data
      this.form.sportsProjectCode = data.sportsProjectCode
      this.form.deviceCode = data.deviceCode
      this.form.deviceName = data.deviceName
      this.form.deviceUnit = data.deviceUnit
      this.form.location = data.location
      this.form.gatewayName = data.gatewayName
      this.form.parentId = data.parentId
      this.form.spaceName = data.spaceName
      this.form.spaceId = data.spaceId
    },
    /**
     * @function 获取全部的角色（树状）
     */
    getRoleData() {
      this.$http.post(apis.User_GetAllRole).then((res) => {
        if (res.data.code === 0) {
          this.roleData = res.data.data.map((item) => {
            const items = { ...item };
            items.key = item.code;
            items.label = item.value;
            return items;
          });
        }
      });
    },
    uploadSuccess(res) {
      console.log(res);
      this.form.venueLogo = res.data.path;
      this.form.venueLogoPath = res.data.url;
      // this.drawAndShareImage()
      this.getCanvas(res.data.url)
      this.$refs.form.validate('venueLogo');
    },
    uploadImageBase(file) {
       this.$http.post(apis.Base64ImageUpload, { Base64Data: file }).then((res) => {
        if (res.data.code === 200) {
          console.log("uploadImageBase", res)
          this.form.venueCircleLogo = res.data.data.path
        }
      })
    },
    /* eslint-disable */
    async getCanvas(venueImages) {
      //canvas 加载图圆形处理
      let canvas = document.createElement("canvas");
      canvas.width = 54;
      canvas.height = 92;
      var ctx = canvas.getContext('2d');
      //加载图片
      let img = new Image();
      img.src = "";
      img.crossOrigin = 'Anonymous';//解决Canvas.toDataURL 图片跨域问题
      img.src = venueImages;
      img.onload = function () {
          let width = 54;
          let height = 54;
          let circle = {
              x: width / 2,
              y: height / 2,
              r: width / 2
          }
          ctx.clearRect(0, 0, width, height);
          //开始路径画圆,剪切处理
          ctx.save();
          ctx.beginPath();
          ctx.arc(circle.x, circle.y, circle.r, 0, Math.PI * 2, false);
          ctx.clip(); //剪切路径
          ctx.drawImage(img, 0, 0, 54, 54);
          //恢复状态
          ctx.restore();
      }
      let img2 = new Image();
      img2.src = require('@/assets/images/map_logo.png')
      img2.onload=function (){
        ctx.drawImage(img2,16,58,22,34);
      }
      clearTimeout(this.timer)
      // this.timer = setTimeout(() => {
      //   this.uploadImageBase(canvas.toDataURL('image/png'))
      //   this.baseImage = canvas.toDataURL('image/png')
      //   }, 1000);
    },
    uploadError(res) {
      console.log(res);
    },
    uploadMapIconSuccess(res) { // 地图图标上传成功返回
      this.form.mapIcon = res.data.url
      this.$refs.form.validate('mapIcon');
    },
    uploadSuccess1(res) {
      this.form.promotionPicturesPath.push(res.data.url);
      this.form.promotionPictures.push(res.data.path);
      this.showPic = false
      this.$nextTick(() => {
        this.showPic = true
      })
      this.$refs.form.validate('promotionPictures');
    },
    uploadError1(err) {
      console.log(err);
      if('string' ==  typeof err){
        this.$message.error(err);  
      }else{
        this.$message.error('文件上传失败，可能是文件过大或网络问题，请更换文件或稍后重试');
      }
      
    },
    openDialog() {
      // TODO 需要从接口获取 场馆/中心
      this.$http.get(apis.getMainDirectionList).then((res) => {
        if (res.data.code === 0) {
          this.seleVenueNameBox = res.data.rows
        }
      })
      // TODO 需要从接口获取  运行项目
      this.$http.get(apis.getMainDirectionList).then((res) => {
        if (res.data.code === 0) {
          this.sportTypeBox = res.data.rows
        }
      })
      this.showMap = false
      this.$nextTick(() => {
        this.showMap = true
        // this.$refs.bMap.init()
      })
    },
    updatePoint(point) {
      this.form.point = point
      this.showMap = false
      this.$nextTick(() => {
        this.showMap = true
      })
      console.log(point)
    },
    removeImage(index) {
      this.form.promotionPicturesPath.splice(index, 1)
      this.form.promotionPictures.splice(index, 1)
    },
  },
};
</script>
<style lang="less" scoped>
.footer-confirm,.footer-cancel{
  line-height: 32px !important;
}
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color:rgba(168,168,168,1);
  font-size:12px;
  margin-top: -15px;
}
.bmap-wrap {
  width: 100%;
  height: 300px;
}
.image-wrap{
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "~@/assets/styles/mixin.less";
.service-wrapper {
  .el-checkbox {
    min-width: 80px !important;
  }
}
</style>
